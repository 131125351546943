<template>
  <div class="asf-forgot-password">
    <template v-if="forgotPasswordRequested">
      <AsfHeading tag="h2" appearance="h4" class="asf-modal__title m-secondary">
        {{ $t('loginPage.heading.successForgotPassword') }}
      </AsfHeading>
      <div ref="body" class="asf-modal__content">
        {{ $t('loginPage.forgotPassword.successDescription') }}
      </div>
      <AsfButton v-if="isFromLoginPage" class="asf-button-primary" full-width @click="$emit('submit:success')">
        {{ $t('loginPage.forgotPassword.successSubmit') }}
      </AsfButton>
    </template>
    <template v-else>
      <AsfAlert v-if="!isFromLoginPage" class="my-8" alignment="center" appearance="error">
        {{ $t('passwordRecovery.tokenExpired') }}
      </AsfAlert>
      <AsfHeading tag="h2" appearance="h4" class="asf-modal__title m-secondary">
        {{ $t('loginPage.heading.forgotPassword') }}
      </AsfHeading>
      <div class="asf-forgot-password__form">
        <div ref="body" class="asf-modal__content">
          {{ $t('loginPage.forgotPassword.description') }}
        </div>
        <AsfForm
          v-bind="resetPasswordFormDefinition"
          :error="forgotPasswordError"
          @form:submitted="handleSubmitResetPassword"
        >
          <template #form-buttons>
            <AsfButton class="asf-button-primary" full-width>
              {{ $t('loginPage.form.resetPassword') }}
            </AsfButton>
          </template>
        </AsfForm>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, reactive, ref } from 'vue'
import { getFormByCountry } from 'shared/config/dynamicForms'

export default defineComponent({
  name: 'ForgotPassword',
  props: {
    isFromLoginPage: { type: Boolean as PropType<boolean>, default: false }
  },
  setup() {
    const { error, forgotPassword } = useCustomer()

    const forgotPasswordRequested = ref(false)
    const resetPasswordModel = reactive<any>({})
    const resetPasswordFormDefinition = {
      id: 'resetPassword',
      name: 'resetPassword',
      fields: getFormByCountry('resetPasswordProfile', 'any'),
      formModel: resetPasswordModel
    }
    const handleSubmitResetPassword = async () => {
      await forgotPassword(resetPasswordModel)
      if (!error.value?.forgotPassword) {
        forgotPasswordRequested.value = true
      }
    }

    return {
      forgotPasswordRequested,
      resetPasswordFormDefinition,
      handleSubmitResetPassword,
      forgotPasswordError: computed(() => error.value.forgotPassword)
    }
  }
})
</script>
